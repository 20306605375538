import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Number inputs`}</em>{` are similar to text inputs, but contain controls used to increase or decrease an incremental value.`}</p>
    <h2 {...{
      "id": "format"
    }}>{`Format`}</h2>
    <ul>
      <li parentName="ul">{`Do not use number inputs when large value changes are expected. They work best for making small, incremental changes that only require a few clicks.`}</li>
      <li parentName="ul">{`Enable the user to also choose to type a number in the field.`}</li>
      <li parentName="ul">{`Ensure that a default value is shown within the field.`}</li>
      <li parentName="ul">{`Use a clear and concise label for the number input.`}</li>
      <li parentName="ul">{`Use sentence-style capitalization (only the first word in a phrase and any proper nouns capitalized) for the label.`}</li>
      <li parentName="ul">{`See further guidance for using number labels within `}<a parentName="li" {...{
          "href": "/components/form/usage"
        }}>{`forms`}</a>{`.`}</li>
    </ul>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.434782608695656%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "number input example",
        "title": "number input example",
        "src": "/static/ea5658b026eeab43b314d06e198af0d2/fb070/number-input-usage-1.png",
        "srcSet": ["/static/ea5658b026eeab43b314d06e198af0d2/d6747/number-input-usage-1.png 288w", "/static/ea5658b026eeab43b314d06e198af0d2/09548/number-input-usage-1.png 576w", "/static/ea5658b026eeab43b314d06e198af0d2/fb070/number-input-usage-1.png 1152w", "/static/ea5658b026eeab43b314d06e198af0d2/c3e47/number-input-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      